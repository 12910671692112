<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  إضافة طالب</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit="createStudent" role="form" class="text-start mt-3">
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>اسم الطالب<span class="red">*</span>
                    <span color='danger' class="error">{{errors.full_name?errors.full_name[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   label="الاسم  بالعربي"
                   v-model='student.fullName'
                    required
                   :invalidFeedback="errors.name"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label> رقم الهاتف<span class="red">*</span>
               <span color='danger' class="error">{{errors.mobile?errors.mobile[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   label="  رقم الهاتف (11 خانه فقط)"
                   v-model='student.mobile'
                    required
                  />
                </div>
                </div>
                <div class='row'>
                <div class="mb-3 col-lg-6">
                      <label class='unrequired'>  البريد الإلكتروني<span class="green">(اختياري)</span></label>
                       <span color='danger' class="error"></span>
                  <vmd-input
                    id="email"
                    type="email"
                    label=" البريد الإلكتروني"
                    v-model='student.email'
                    :invalidFeedback="errors.email"
                  />
                </div>
                   <div class="mb-3 col-lg-6">
                    <label class='unrequired'> رابط الفيسبوك<span class="green">(اختياري)</span>
                      <span color='danger' class="error">{{errors.facebook_url?errors.facebook_url[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   label=" رابط الفيسبوك"
                    v-model='student.facebookUrl'
                  />
                </div>
                       </div>
                           <div class='row'>
                     <div class="mb-3 col-lg-6">
                    <label> الفرقة <span class="red">*</span>
                    <span color='danger' class="error">{{errors.grade_id?errors.grade_id[0]:''}}</span>
                    </label>
                      <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedGrade"
                        :options='grades'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الفرقة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                       </div>
                              <div class='row'>
                       </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                  type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
        </div>
      </div>
      </div>
    </div>
    </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import $ from 'jquery'
import swal from 'sweetalert'
export default {
  name: 'AddStudent',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      student: {},
      groups: [],
      grades: [],
      areas: [],
      selectedGroup: '',
      selectedGrade: '',
      selectedArea: '',
      errors: []
    }
  },
  created () {
    const grades = []
    this.$http.get(`${this.$hostUrl}grades`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          grades.push({
            name: value.name,
            id: value.id
          })
        })
        this.grades = grades
      })
  },
  methods: {
    async createStudent (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.student.fullName)) {
        swal('اسم الطالب يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('full_name', this.student.fullName)
      if (this.student.email) {
        formData.append('email', this.student.email)
      }
      if (this.student.facebookUrl) {
        formData.append('facebook_url', this.student.facebookUrl)
      }
      formData.append('mobile', this.student.mobile)
      formData.append('user_name', this.student.username)
      formData.append('grade_id', this.selectedGrade.id)
      this.errors = await this.postRequest(formData, this.$hostUrl + 'students', '/students')
    }
  }
}
</script>
<style >
.error{
  color:#f44335 !important;
  font-size:13px;
   padding-right: 25px;
}
.unrequired{
      display: flex;
    gap: 5px;
}

</style>
